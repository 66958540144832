.brand,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.navbar,
.td-name,
a,
body,
button,
h1,
h2,
h3,
h4,
h5,
h6,
input,
p,
select,
td,
textarea {
    font-family: poppins, Helvetica Neue, Helvetica, Arial, "sans-serif" !important;
    font-size: 14px
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.05);
    background-image: linear-gradient(160deg, #3BC5D1, #99dce2);
    border-radius: 3px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 3px;
}

.main-navbar button[class*="button"]:not(button[aria-label="copy"]):not(button[aria-label="open wallet"]),
button[type*="submit"][class*="button"] {
    background-image: linear-gradient(to right, #3BC5D1, #3ae1ef, #004A78) !important;
    transition: all linear 0.3s;
    background-size: 300% 100% !important;
    border-radius: 50px;
}

.main-navbar button[class*="button"]:hover,
button[type*="submit"][class*="button"]:hover {
    background-position: 100% 0;
}

.select-chains {
    border: 1px solid !important;
    border-color: #004A78 !important;
    height: 40px !important;
}

div[id*="__next"]>div[class*="css-"] {
    min-width: fit-content;
}

.jumbotron h1.chakra-heading {
    color: #004A78;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
}

.gas-stats-custom {
    display: none !important;
}

.home-stats-main {
    margin-top: -60px;
    z-index: 2;
    position: relative;
}

/*
  .home-stats-grid{
    background-color: rgba(230,230,230,0.8);
    border-radius: 10px;
    box-shadow: 1px 0px 10px 0px rgba(200,200,200,0.8);
    -webkit-box-shadow: 1px 0px 10px 0px rgba(200,200,200,0.8);
    -moz-box-shadow: 1px 0px 10px 0px rgba(200,200,200,0.8);
  }
  */
div.main-navbar {
    border-top: solid 1px #eee;
    padding: 20px 30px;
}

.main-navbar li,
.main-navbar li a {
    color: #004A78;
    font-size: 16px;
}

.divide-y>:not([hidden])~:not([hidden]) {
    border-top: 1px solid #dddddd;
}

.divide-x>:not([hidden])~:not([hidden]) {
    border-left: 1px solid #dddddd;
}

.home-latest-block-link>:not(a),
.home-latest-block-link svg {
    width: 20px;
    height: 20px;
}

.home-latest-block-link a {
    font-size: 20px !important;
    color: #004A78;
}

div[data-status*="warning"] {
    background: #ecf9fb;
    border-radius: 0;
    padding: 8px 15px !important;
}

.homepage-section [data-label*="hero"] {
    background-size: inherit;
    background: url(/assets/images/homepage-dna-graphics.png) no-repeat 10% -50px, linear-gradient(180deg, #ACE6F2, #F2F2F2 90%);
}

.homepage-section .home-stats-grid {
    /*    border: solid 1px #eee;
    background: rgba(255, 255, 255, 0.4); */
    box-shadow: none;

}

.chakra-tabs.white-shadow-container {
    position: relative;
    top: 30px;
}

.white-shadow-container,
.swagger-ui .opblock.opblock-get {
    border: solid 1px #eee !important;
    border-top: solid 1px #fff !important;
    background: rgba(255, 255, 255, 0.4) !important;
    border-radius: 10px !important;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.03) !important;
    backdrop-filter: blur(10px);
    margin: 0 0 30px !important;
    grid-gap: 0 !important;
}

.white-shadow-container .daily-transaction-container {
    background: none;
    padding: 20px 30px;
}

.white-shadow-container .daily-transaction-container>div>div:nth-child(1)>p {
    padding: 5px 0 0;
    font-size: 14px;
    color: #718096;
}

.white-shadow-container .daily-transaction-container>div>div:nth-child(2)>p {
    font-size: 20px;
    font-weight: 500;
    margin: -10px 0 -20px;
}

.white-shadow-container>[class*="-tabs"].white-shadow-container {
    border: none;
    background: none;
    backdrop-filter: blur(0);
    margin: 1px 0 0 !important;
    box-shadow: none !important;
}

main.css-0 {
    margin: -70px 0 0;
}

.homepage-section .home-stats-grid>div,
.homepage-section .home-stats-grid>a,
.white-shadow-container>a {
    padding: 20px;
    background: none;
}

.homepage-section .home-stats-grid>a {
    border-right: solid 1px rgba(0, 0, 0, 0.05);
}

.homepage-section .home-stats-grid>a:first-child,
.homepage-section .home-stats-grid>div:nth-child(2) {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.chakra-tabs .chakra-tabs__tablist {
    margin: 0;
    border-bottom: solid 1px #eee;
    column-gap: 0 !important;
}

.chakra-table div[data-status*="warning"] {
    margin: 0 -30px;
    width: calc(100% + 60px);
}

table[class*="table"] tbody>tr[style*="opacity: 1"],
table[class*="table"] tbody>tr[class*="css-0"] {
    transition: all linear 0.3s;
}

table[class*="table"] tbody>tr[style*="opacity: 1"]:hover,
table[class*="table"] tbody>tr[class*="css-0"]:hover {
    background: rgba(0, 0, 0, 0.02);
}

div[data-popper-placement*="bottom-start"]>div {}

/*
    Arief - Halaman transaksi
  */

.home-latest-container {
    width: 100%;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
}

.home-latest-container .white-shadow-container {
    width: 100%;
}

.home-latest-container .white-shadow-container .table-tab-container {
    margin-top: -62px;
    background-color: transparent !important;
    justify-content: end;
    padding-right: 0px !important;
}

.home-latest-container .white-shadow-container .table-tab-container button[role=tab] {
    border-right: 0px !important;
    margin-top: -35px !important;
    padding: 32px 20px !important;
}

.home-latest-container .white-shadow-container .table-tab-container button[role=tab]:first-child {
    border-top-left-radius: 0px !important;
}

.home-latest-container .white-shadow-container .table-tab-container button[role=tab]:nth-last-of-type(2) {
    border-top-right-radius: 10px !important;
}

.chakra-table thead th {
    background-color: #ffffff;
    -webkit-padding-start: 6px;
    padding-inline-start: 6px;
    -webkit-padding-end: 20px;
    padding-inline-end: 20px;
    padding: 20px 10px;
    color: #004A78;
    font-weight: 700;
}

.chakra-table thead {
    border-bottom: 1px solid;
    border-color: #38CEEB;
}

.chakra-table thead th:first-child,
.chakra-table tbody tr td:first-child {
    padding-left: 30px;
}

.chakra-table thead th:last-child,
.chakra-table tbody tr td:last-child {
    padding-right: 30px;
}

.tab-container {
    padding-top: 20px;
}

.chakra-tabs__tablist {
    padding: 0px 20px 0px 0px !important;
    background-color: #ffffff !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.chakra-tabs__tablist button[role=tab] {
    background-color: #F0F1F1;
    padding: 30px 20px;
    border-width: 0;
    border-radius: 0px;
    border-right: 1px solid #fff;
}

.chakra-tabs__tablist button[role=tab]:first-child {
    border-top-left-radius: 10px !important;
}

/* .chakra-tabs__tablis button[role=tab]:last-child {
    border-top-right-radius: 10px !important;
  } */
.tabs-charka-api button:nth-child(1) {
    border-top-left-radius: 10px !important;
}
.tabs-charka-api button:nth-child(2) {
    border-top-right-radius: 10px !important;
}
.chakra-tabs__tablist button[role=tab][aria-selected=true] {
    background-color: #004A78;
    color: #ffffff;
}

.chakra-tabs__tab-panel .transaction-detail-info-label {
    padding: 0 20px 10px 30px;
}

.chakra-tabs__tab-panel .transaction-detail-info-value {
    padding: 0 30px 10px 20px;
}

.chakra-tabs__tab-panel .transaction-detail-view-hide-details {
    padding: 0 30px 30px 30px;
}

.chakra-tabs__tab-panel>div>[class*="transaction-detail-info"]:first-child {
    padding: 30px 20px 10px 30px;
}

.chakra-tabs__tab-panel>div>[class*="transaction-detail-info"]:nth-child(2) {
    padding: 30px 30px 10px 20px;
}

.chakra-tabs__tab-panel>div>[class*="transaction-detail-info"]:last-child {
    padding: 0 30px 60px 20px;
}

/*  .chakra-tabs__tab-panel > div > :not([class*="transaction-detail-info"]) a { padding:0 30px 30px; display: inline-block;} */

[name*="__cutLink"] a.chakra-link {
    padding: 0 0 30px 30px;
    display: inline-grid;
}

.chakra-tabs__tab-panel .transaction-detail-view-hide-details [name*="__cutLink"] a.chakra-link {
    padding: 0 0 30px;
}

.chakra-tabs__tab-panel .action-bar-container {
    padding: 20px 20px;
    margin: 0;
    border-bottom: solid 1px #eee;
    width: 100%;
}

.action-bar-container {
    padding: 20px 20px !important;
    margin: 0;
    border-bottom: solid 1px #eee;
}

.token-actionbar.action-bar-container {
    padding: 20px 20px;
    margin: 0;
    border-bottom: solid 1px #eee;
    max-width: 100%;
}

p.no-data {
    padding: 20px;
    text-align: center;
}

p.description-state {
    padding: 20px;
    margin: 0;
}

.inventory-container {
    padding: 30px;
}


.table-tab-pagination {
    justify-content: end;
    padding-right: 20px;
    flex-grow: 1;
    padding-top: 20px;
}

.table-tab-pagination>div {
    margin: 0px !important;
    padding: 0px !important;
}

.table-tab-pagination>div button {
    border: 0px !important;
    border-radius: 0px !important;
    background-color: #eeeeee;
    opacity: 1 !important;
}

.table-tab-pagination>div:first-child button {
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
}

.table-tab-pagination>div:last-child button {
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
}

.table-tab-pagination>div button[data-selected=true] {
    background-color: #eeeeee;
    color: #004A78;
}



ul[role*="list"]>li>div>div {
    position: relative;
}

ul[role*="list"]>li>div>div:nth-child(1) {
    z-index: 10;
}

ul[role*="list"]>li>div>div:nth-child(2) {
    z-index: 9;
}

ul[role*="list"]>li>div>div:nth-child(3) {
    z-index: 8;
}

ul[role*="list"]>li>div>div:nth-child(4) {
    z-index: 7;
}

ul[role*="list"]>li>div>div:nth-child(5) {
    z-index: 6;
}

ul[role*="list"]>li>div>div:nth-child(6) {
    z-index: 5;
}

ul[role*="list"]>li>div>div:nth-child(7) {
    z-index: 4;
}

ul[role*="list"]>li>div>div:nth-child(8) {
    z-index: 3;
}

ul[role*="list"]>li>div>div:nth-child(9) {
    z-index: 2;
}

ul[role*="list"]>li>div>div:nth-child(10) {
    z-index: 1;
}

.address-details .chakra-tabs__tab-panels [role*="tabpanel"]>div+.table-tab-pagination {
    position: absolute;
    right: 0;
    bottom: -47px;
}

.address-details .white-shadow-container .white-shadow-container {
    padding: 0px;
    box-shadow: unset !important;
    background: transparent !important;
    margin: 0px !important;
    border: 0px !important;
}

.address-details .white-shadow-container .token-nft-container .white-shadow-container .chakra-tabs__tab-panel {
    padding: 30px;
}

.swagger-ui .opblock .opblock-summary {
    padding: 15px 20px !important;
}

button .opblock-summary-method {
    background: #004A78 !important;
    border-radius: 50px !important;
    min-width: 100px !important;
    height: 40px;
    padding: 10px 20px !important;
}

.swagger-ui .opblock-control-arrow svg {
    width: 15px;
}

.swagger-ui .btn {
    background: #3BC5D1 !important;
    border: none !important;
    border-radius: 50px !important;
    color: #fff !important;
    padding: 10px 20px !important;
    box-shadow: none !important;
    height: 40px;
    min-width: 120px !important;
}

.swagger-ui .opblock.opblock-get {
    margin: 0 0 20px !important;
}

.swagger-ui .opblock.opblock-get .opblock-summary {
    border: none !important;
}

.swagger-ui .opblock .opblock-section-header {
    border-bottom: solid 1px #38CEEB;
}

.swagger-ui .opblock .opblock-section-header h4 {
    font-size: 16px !important;
    font-weight: 600;
    color: #004A78 !important;
}

.swagger-ui .opblock .opblock-section-header h4 span:after {
    display: none;
}

.swagger-ui .opblock .parameters-container>.table-container,
.swagger-ui .opblock .responses-wrapper .responses-inner {
    padding: 0;
}

.swagger-ui .opblock .parameters-container>.table-container tr th,
.swagger-ui .opblock .parameters-container>.table-container tr td,
.swagger-ui .opblock .responses-wrapper .responses-inner tr th,
.swagger-ui .opblock .responses-wrapper .responses-inner tr td {
    padding: 20px;
}

.swagger-ui .opblock .parameters-container>.table-container tr th,
.swagger-ui .opblock .responses-wrapper .responses-inner thead tr td {
    font-size: 14px;
    font-weight: 400;
    color: #004A78;
}

.swagger-ui .parameters-col_description input,
.swagger-ui .opblock-body select {
    border-radius: 30px !important;
    border: 1px solid #d9d9d9 !important;
    box-shadow: none;
    height: 40px;
    padding: 0 20px;
}

.model-example ul.tab li {
    background: #F2F2F2;
    padding: 10px 15px !important;
    font-size: 14px
}

.model-example ul.tab li:first-child {
    border-radius: 10px 0 0;
}

.model-example ul.tab li:last-child {
    border-radius: 0 10px 0 0;
}

.model-example ul.tab li::after {
    display: none;
}

.model-example ul.tab li.active {
    background: #004A78;
    color: #fff !important;
}

section[title*="Contract"],
section[title*="Deployed"] {
    padding: 20px 30px;
}

form[novalidate] input {
    border-radius: 50px;
}

.address-contract-container {
    margin: 20px 0 0;
}

.address-contract-container .rw-contract-verified>div:first-child {
    border-bottom: solid 1px #38CEEB;
    margin: -25px 0 0;
    padding: 20px;
}

.address-contract-container div[class*="accordion"]>section[class*="accordion__item"] {
    padding: 10px 20px;
}

.address-contract-container .contract-code-verified {
    padding: 30px;
}

.address-contract-container .contract-code-verified>div:first-child {
    margin: -30px -30px 30px;
}

.address-contract-container .contract-code-verified>div:first-child div {
    border-radius: 0;
}

.chakra-form-control label{
    background-color: transparent !important;
}

.contract-code-verified>div>div,
.contract-code-verified>div>section {
    padding: 20px 30px;
}

.contract-code-verified .chakra-tabs__tablist button[role=tab] {
    padding: 10px 20px;
}

[role*="group"]>div>div {
    border-radius: 50px;
}

footer {
    padding: 15px !important;
}

/*=============================== RESPONSIVE ==========================*/

@media screen and (min-width: 1000px) {
    .inventory-container>div {
        width: auto;
    }
}

@media screen and (max-width: 1000px) {

    [class*="__tab-panels"]>[class*="__tab-panel"]>div>div,
    [class*="__tab-panels"]>[class*="__tab-panel"] .action-bar-container+div>div,
    .home-latest-tsx>div {
        padding: 20px;
        position: static;
    }

    [class*="__tab-panels"]>[class*="__tab-panel"] .action-bar-container+div {
        padding: 0px;
    }

    .main-navbar+div[class*="css-"]+div[class*="css-"]>div[class*="css-"]>div[class*="css-"] {
        padding: 70px 30px 30px;
    }

    .main-navbar~.homepage-section>div[class*="css-"] {
        padding: 0 30px 30px;
    }

    .homepage-particle-container {
        width: calc(100% + 60px) !important;
        margin: 0 -30px !important;
    }
}

@media screen and (max-width: 768px) {
    .homepage-section [data-label*="hero"].jumbotron {
        padding: 0 40px;
    }

    .homepage-section .jumbotron>div:first-child {
        display: none;
    }

    .home-latest-container {
        grid-template-columns: 1fr;
    }

    .homepage-particle-container+div[class*="css-"] {
        width: 100%;
    }

    .homepage-particle-container+div[class*="css-"]>div[class*="css-"] {
        display: block;
    }
}

@media screen and (max-width: 480px) {
    .home-latest-container .white-shadow-container .table-tab-container button[role=tab] {
        padding: 34px 10px !important;
    }

    .home-latest-container .white-shadow-container .table-tab-container {
        margin-top: -66px;
    }
    .home-latest-container .white-shadow-container .table-tab-container button[role=tab] {
        margin-top: 35px !important;
    }
    .home-latest-container .white-shadow-container .table-tab-container {
        justify-content: center;
    }
    .home-latest-container .white-shadow-container .table-tab-container button[role=tab] {
        width: 50%;
    }
    .address-entity > a:not(section.address-details .chakra-tabs__tab-panel > div > div > div > div:nth-last-child(2) a){
        max-width: 40px;
    }
    section.address-details .chakra-tabs__tab-panel > div > div > div > div:nth-last-child(2){
        display: flex;
        align-items: start;
        max-width: 100%;
        overflow: hidden;
    }
    .top-accounts-page > .white-shadow-container > div:not(.hidden){
        padding: 20px;
    }
    .blocks-container .action-bar-container{
        position: static;
        margin: 20px 0;
        max-width: 100%;
    }
    .address-details .white-shadow-container .token-nft-container .white-shadow-container .chakra-tabs__tab-panel{
        padding: 0;
    }
}

.footer {
    background: #0bcbf1;
    background: -o-radial-gradient(circle,rgba(11,203,241,.4) 60%,rgba(169,241,255,.4) 100%);
    background: radial-gradient(circle,rgba(11,203,241,.4) 60%,rgba(169,241,255,.4) 100%)
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    justify-content: space-between;
    align-items: center;
}

.footer .copyright {
    color: #444;
}

.footer .credits, .footer-nav {
    line-height: 60px;
    margin: 0;
    text-align: center;
    width: 100%;
}

.footer .footer-nav ul {
    font-weight: 400;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    align-items: center;
}

.footer .footer-nav ul li {
    text-align: center;
    display: flex;
    align-items: center;
    margin-left: 30px;
}

.footer .credits,.footer-nav {
    line-height: 60px;
    margin: 0;
    text-align: center;
    width: 100%
}

/* .footer .credits {
    border-bottom: 1px solid hsla(0,0%,100%,.5)
} */

.footer .credits img {
    width: 100px;
    display: block;
    margin: 0 auto;
}
.footer .footer-nav ul li a {
    justify-content: center;
    border-radius: 50px;
    height: 40px;
    line-height: 25px;
    min-width: 40px;
    padding: 8px;
    width: 40px;
    background-color: #17a2b8;
    transition: 0.3s ease;
}
.footer .footer-nav ul li a use {
    color: #fff !important;
}
.footer .footer-nav ul li a:hover {
    background-color: #18c6e1;
}
footer .footer-nav ul li a > div {
    margin-right: 0;
}
.footer .footer-nav ul li a {
    margin-left: 5px;
}
.footer .footer-nav ul li a:first-child {
    margin-left: 0;
}
@media screen and (max-width: 768px) {
    .footer-custome .row {
        display: block;
    }
    .footer .footer-nav ul li:nth-child(1) {
        display: none;
    }
    .footer .footer-nav ul {
        justify-content: center;
    }
    .footer .footer-nav ul li {
        margin-left: 0;
    }
}
.row-content-api .left-content .title-top {
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-padding-start: var(--chakra-space-1);
    padding-inline-start: var(--chakra-space-1);
    -webkit-padding-end: var(--chakra-space-1);
    padding-inline-end: var(--chakra-space-1);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: var(--chakra-radii-sm);
    font-weight: var(--chakra-fontWeights-bold);
    background: black .50;
    color: black .500;
    box-shadow: var(--badge-shadow);
    --badge-bg: colors . black .100;
    --badge-color: colors . black .800;
    margin-bottom: 10px;
}
.row-content-api .left-content .title-bottom {
    -webkit-padding-start: var(--chakra-space-1);
    padding-inline-start: var(--chakra-space-1);
    -webkit-padding-end: var(--chakra-space-1);
    padding-inline-end: var(--chakra-space-1);
    font-size: 24px;
    border-radius: var(--chakra-radii-sm);
    font-weight: var(--chakra-fontWeights-bold);
    background: black .50;
    color: black .500;
    box-shadow: var(--badge-shadow);
    --badge-bg: colors . black .100;
    --badge-color: colors . black .800;
    margin-bottom: 10px;
}
.row-content-api .left-content .title-bottom .hela-text {
    -webkit-padding-start: var(--chakra-space-1);
    padding-inline-start: var(--chakra-space-1);
    -webkit-padding-end: var(--chakra-space-1);
    padding-inline-end: var(--chakra-space-1);
    font-size: 24px;
    border-radius: var(--chakra-radii-sm);
    font-weight: var(--chakra-fontWeights-bold);
    background: black .50;
    color: #3ae1ef;
    box-shadow: var(--badge-shadow);
    --badge-bg: colors . black .100;
    --badge-color: colors . black .800;
    margin-bottom: 10px;
}
.row-content-api .left-content .desc {
    -webkit-padding-start: var(--chakra-space-1);
    padding-inline-start: var(--chakra-space-1);
    -webkit-padding-end: var(--chakra-space-1);
    padding-inline-end: var(--chakra-space-1);
    font-size: 14px;
    border-radius: var(--chakra-radii-sm);
    font-weight: 400;
    background: black .50;
    color: black .400;
    box-shadow: var(--badge-shadow);
    --badge-bg: colors . black .100;
    --badge-color: colors . black .800;
    margin-bottom: 10px;
}
.group-button {
    margin-bottom: 30px;
}
.group-button .btn-pricing {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    line-height: 1.2;
    font-weight: 600;
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    overflow: hidden;
    height: var(--chakra-sizes-8);
    min-width: unset;
    background: var(--chakra-colors-blue-600);
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: var(--chakra-fontSizes-sm);
    -webkit-padding-start: var(--chakra-space-8);
    padding-inline-start: var(--chakra-space-8);
    -webkit-padding-end: var(--chakra-space-8);
    padding-inline-end: var(--chakra-space-8);
    padding-top: var(--chakra-space-5);
    padding-bottom: var(--chakra-space-5);
    border-radius: var(--chakra-radii-full);
    background-color: #3BC5D1;
    color: #ffffff;
}
.group-button .btn-docs {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    line-height: 1.2;
    font-weight: 600;
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    overflow: hidden;
    height: var(--chakra-sizes-8);
    min-width: unset;
    background: var(--chakra-colors-blue-600);
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: var(--chakra-fontSizes-sm);
    -webkit-padding-start: var(--chakra-space-8);
    padding-inline-start: var(--chakra-space-8);
    -webkit-padding-end: var(--chakra-space-8);
    padding-inline-end: var(--chakra-space-8);
    padding-top: var(--chakra-space-5);
    padding-bottom: var(--chakra-space-5);
    border-radius: var(--chakra-radii-full);
    background-color: transparent;
    color: #000;
}
.row-content-api {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0;
}
.row-content-api .left-content {
    width: 50%;
}
.row-content-api .right-content {
    width: 50%;
}
.row-content-api .right-content img {
    width: 100%;
}
.tabs-charka-api .chakra-tabs__tablist button[role=tab] {
    padding: 10px !important;
}
.tabs-charka-api button span {
    font-size: 12px !important;
    margin-left: 5px;
}
.tabs-charka-api .chakra-tabs .chakra-tabs__tablist {
    justify-content: center;
    background: transparent !important;
    border-bottom: 0px solid transparent !important;
}
.res-filter > div {
    z-index: 999 !important;
}
.tabs-charka-api {
    position: relative;
}
.row-select .chakra-select__wrapper {
    width: 200px;
    position: absolute;
    right: 20px;
    top: 0;
}
.row-select .chakra-select {
    border: 1px solid #e9ecef !important;
}
@media screen and (max-width: 768px) {
    .row-content-api .left-content {
        width: 100%;
    }
    .row-content-api .right-content {
        display: none;
    }
    .group-button .btn-pricing {
        width: 120px;
        font-size: 12px;
    }
    .group-button .btn-docs {
        width: 120px;
        font-size: 12px;
        margin-left: 20px;
    }
    .row-content-api {
        padding: 20px 0;
    }
    .row-select .chakra-select__wrapper {
        position: relative;
        right: 0;
        width: 100%;
        margin-bottom: 15px;
    }
    .row-tabs-txs .chakra-tabs__tablist {
        display: flex;
        flex-wrap: wrap;
        padding-right: 0 !important;
        margin-bottom: 10px !important;
    }
    .row-tabs-txs .chakra-tabs__tablist button {
        width: 25%;
        padding: 5px 10px;
        font-size: 10px;
    }
    .row-tabs-txs .chakra-tabs__tablist button:nth-child(1) {
        width: 25%;
    }
    .row-tabs-txs .chakra-tabs__tablist button:nth-child(2) {
        width: 35%;
    }
    .row-tabs-txs .chakra-tabs__tablist button:nth-child(3) {
        width: 20%;
    }
    .row-tabs-txs .chakra-tabs__tablist button:nth-child(4) {
        width: 20%;
    }
    .row-tabs-txs .transaction-detail-info-label {
        padding: 0 10px !important;
        margin-top: 0 !important;
    }
    /* .row-tabs-txs .transaction-detail-info-label:nth-child(1) {
        padding: 10px 10px 0 10px !important;
    } */
    .row-tabs-txs .chakra-tabs__tab-panel>div>[class*="transaction-detail-info"]:nth-child(2) {
        padding: 10px 15px !important;
    }
    .row-tabs-txs .transaction-detail-info-value {
        padding: 5px 10px !important;
    }
    .row-tabs-txs .css-0 {
        font-size: 12px;
    }
}
.text-row-copy {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.row-payment {
    border: solid 1px #eee;
    padding: 40px 0;
    border-radius: 8px;
    position: relative;
}
.row-payment::before {
    content: "";
    width: 0.5px;
    height: 90%;
    background: #eee;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
}

.address-entity{
    width: fit-content;
}

.address-details .chakra-tabs.white-shadow-container{
    top: 0px;
}

.address-entity > div > div > div {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}